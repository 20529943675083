.App {
  text-align: center;
}

.loader-logo {
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .loader-logo {
    animation: loader-logo-spin infinite 1.25s ease-in-out;
  }
}

@keyframes loader-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
